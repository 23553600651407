import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import CardWrapper from '@/core/components/ui/CardWrapper.vue';
import { UserVerificationStepsEnum } from '@/core/packages/shared-library';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'account-detail-page',
    components: {
        CardWrapper,
        BaseModal,
    },
    data() {
        return {
            UserVerificationStepsEnum,
            verificationConfirmDialog: false,
            submitting: false,
        };
    },
    computed: {
        ...mapGetters(USERS_STORE, ['isUserVerificationActive', 'user']),
        currentStep() {
            let step = 0;
            const tpProfile = this.user;
            if (this.isUserVerificationActive) {
                step = 1;
            }
            if (tpProfile.userVerificationStatus === '1') {
                step = 2;
            }
            if (tpProfile.userVerificationStatus === '2') {
                step = 4;
            }
            return step;
        },
    },
    methods: {
        ...mapActions(USERS_STORE, ['activateUserAccount', 'initializeUserState']),
        cancel() {
            this.verificationConfirmDialog = false;
        },
        confirmActivation() {
            this.verificationConfirmDialog = true;
        },
        activateAccount() {
            this.submitting = true;
            this.activateUserAccount(this.user.id)
                .then(() => {
                this.$notify.success({
                    message: 'Successfully sent the request for verification'
                });
                this.initializeUserState();
                this.verificationConfirmDialog = false;
            })
                .catch((e) => {
                this.$notify.error({
                    message: e || 'Error activating your account.'
                });
            })
                .finally(() => {
                this.submitting = false;
            });
        },
        nextSection() {
            this.$router.push('/account/public-profile');
        },
        findJob() {
            this.$router.push('/find-jobs');
        }
    }
});
